<template>
  <el-dialog
    v-bind="{title:'新增材料信息',width:'600px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules" class="mr-20">
      <el-form-item label="标题" prop="title">
        <el-input class="w-100-p" placeholder="请输入材料信息标题" v-model.trim="submitForm.title" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <el-radio-group v-model="submitForm.isTop">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发布时间" prop="issuDatTim">
        <el-date-picker
          class="w-100-p"
          v-model="submitForm.issuDatTim"
          type="datetime"
          placeholder="不选时，默认为提交时间"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公告内容" prop="content">
        <el-input type="textarea" placeholder="请输入材料信息内容" :autosize="{
          minRows: 10
        }" v-model="submitForm.content"></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addMaterial } from '@/apis/notice/materialNotice'
// 通知类型
const topiType = 30
export default {
  props: {
    editData: { type: Object },
    visible: { type: Boolean, default: false }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    },
    currTitle () {
      return this.titles[this.submitForm.topiType]
    }
  },
  data () {
    return {
      submitForm: {
        topiId: '',
        topiType, // 材料信息
        title: '',
        isTop: 1, // 是否置顶 1是 0否
        issuDatTim: '', // 发布日期时间
        forbid: 1, // 禁用启用 1启用 2禁用
        content: ''
      },
      rules: {
        title: { required: true, message: '请输入' },
        content: { required: true, message: '请输入' }
      },
      loading: false
    }
  },
  methods: {
    handlerOpen () {
      if (this.editData) {
        console.log(this.editData)
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          addMaterial(this.submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
