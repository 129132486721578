<template>
  <page-container>
    <base-search-form
      @search="onSearch"
      @reset="onReset"
      :loading="loading"
    >
      <el-form-item label="标题:">
        <el-input
          v-model.trim="searchForm.title"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
    </base-search-form>

    <div class="mb-15">
    </div>

    <base-table
      class="mb-5"
      id="materialNotice"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="editVisible = true">新 增</el-button>
      </template>
      <vxe-column type="seq" title="序号" width="80" align="center" />
      <vxe-column field="title" title="标题" min-width="150" />
      <vxe-column field="content" title="内容" min-width="150" />
      <vxe-column field="issuDatTim" title="发布时间" min-width="170" />
      <vxe-column field="isTopName" title="是否置顶" />
      <vxe-column field="forbidName" title="状态" />
      <vxe-column title="操作" min-width="120">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button
            @click="handleDisable(row)"
            :type="row.forbid == 1 ? 'danger' : 'primary'"
          >{{row.forbid == 1?'禁用': '启用'}}</el-button>
          <el-button type="danger" @click="handleDel(row)">删除</el-button>
        </template>
      </vxe-column>
    </base-table>

    <!-- <vxe-pager
      :loading="loading"
      :current-page="tablePage.pageNum"
      :page-size="tablePage.pageSize"
      :total="tablePage.totalSize"
      :layouts="[
        'Sizes',
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Total',
      ]"
      @page-change="handlePageChange"
    /> -->

    <add :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList" />
  </page-container>
</template>

<script>
import { getMaterialList, delMaterial, disableMaterial } from '@/apis/notice/materialNotice'
import Add from './Add'
import PageContainer from '../../../components/common/PageContainer/index.vue'
import BaseSearchForm from '../../../components/common/BaseSearchForm/index.vue'
export default {
  components: {
    Add,
    PageContainer,
    BaseSearchForm
  },
  data () {
    return {
      searchForm: {
        title: '', // 标题
        topiType: 30 // 类型
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      editData: null,
      editVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    handleDisable (row) {
      const operationName = row.forbid == 1 ? '禁用' : '启用'
      const operationId = row.forbid == 1 ? 2 : 1
      this.$confirm(`确定要${operationName}该数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        disableMaterial({
          forbid: operationId,
          topiId: row.topiId
        }).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success(operationName + '成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    handleEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { tablePage } = this
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getMaterialList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map(item => {
            item.isTopName = item.isTop == 1 ? '是' : '否'
            return item
          })
          tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    handleDel (row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMaterial({ ids: [row.topiId] }).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  }
}
</script>
